:root {
    --primary: #000000;
    --primary-2: #111;
    --secondary: #ffffff;
    --secondary-2: #f1f3f5;
    --hover: rgba(255, 255, 255, 0.075);
    --hover-1: rgba(255, 255, 255, 0.15);
    --hover-2: rgba(255, 255, 255, 0.25);
    --selection: var(--purple);
  
    --text-base: white;
    --text-primary: white;
    --text-secondary: black;
  
    --accents-0: #212529;
    --accents-1: #343a40;
    --accents-2: #495057;
    --accents-3: #868e96;
    --accents-4: #adb5bd;
    --accents-5: #ced4da;
    --accents-6: #dee2e6;
    --accents-7: #e9ecef;
    --accents-8: #f1f3f5;
    --accents-9: #f8f9fa;
  
    --cyan: #22b8cf;
    --green: #37b679;
    --red: #da3c3c;
    --pink: #a239c5;
    --purple: #f81ce5;
    --blue: #0070f3;
    --violet-light: #7048e8;
    --violet: #5f3dc4;
  
    --font-sans: -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue',
      'Helvetica', sans-serif;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  *:focus {
    @apply outline-none ring-2 ring-pink ring-opacity-50;
  }
  
  html {
    height: 100%;
    box-sizing: border-box;
    touch-action: manipulation;
    font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html,
  body {
    font-family: var(--font-sans);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--primary-2);
    color: var(--text-primary);
  }
  
  body {
    position: relative;
    min-height: 100%;
    margin: 0;
  }
  
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  #nav {
    -webkit-backdrop-filter: saturate(180%) blur(5px);
    backdrop-filter: saturate(180%) blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  #skip {
    margin-top: -80px;
  }
  
  #mt-0 {
    margin-top: 0;
  }