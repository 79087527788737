#bg {
    background-image: url(../public/topography.svg) !important;
}

@tailwind base;
@import './base.css';

@tailwind components;
@import './components.css';

@tailwind utilities;
